import { useErc721CollectionContract } from 'hooks/useContract'
import { Address } from 'wagmi'

export const requiresApprovalNftAll = async (
  contract: ReturnType<typeof useErc721CollectionContract>,
  account: Address,
  spenderAddress: Address,
) => {
  try {
    if (!contract) return true
    const response = await contract.read.isApprovedForAll([account, spenderAddress])
    const returnVal = response !== true
    return returnVal
  } catch (error) {
    console.error(error)
    return true
  }
}
